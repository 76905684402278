import React, { useContext, useEffect } from 'react';
import { AnalyticsContext } from '@leuven2030/framework/Analytics/AnalyticsProvider';

const AnalyticsWindow = () => {
  const { analytics } = useContext(AnalyticsContext);

  useEffect(() => {
    const removeWindowEnterListener = analytics.on('windowEnter', () => {
      // do stuff when visitor enters window
    });

    const removeWindowLeftListener = analytics.on('windowLeft', () => {
      // do stuff when visitor leaves window
    });

    const removeTabHiddenListener = analytics.on('tabHidden', () => {
      // do stuff when tab hidden
    });
    const removeTabVisibleListener = analytics.on('tabVisible', () => {
      // do stuff when tab visible
    });

    return () => {
      removeTabHiddenListener();
      removeTabVisibleListener();
      removeWindowEnterListener();
      removeWindowLeftListener();
    };
  }, []);

  return <></>;
};

export default AnalyticsWindow;
