import Analytics, { AnalyticsInstance } from 'analytics';
import React, { createContext, useEffect, useState } from 'react';
import doNotTrack from 'analytics-plugin-do-not-track';
import tabEventsPlugin from 'analytics-plugin-tab-events';
import windowEventsPlugin from 'analytics-plugin-window-events';
import AnalyticsMatomo from '@leuven2030/framework/Analytics/AnalyticsMatomo';
import AnalyticsMatomoTracker from '@leuven2030/framework/Analytics/AnalyticsMatomoTracker';

export const AnalyticsContext = createContext<{
  analytics?: AnalyticsInstance;
  tracker?: any;
  setTracker?: (value) => void;
}>({});

const AnalyticsProvider = ({ children }) => {
  const [tracker, setTracker] = useState(null);
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (analytics) {
      return;
    }
    if (!tracker) {
      return;
    }
    const newAnalytics = Analytics({
      app: 'leuven2030-client',
      version: '0.0.1',
      plugins: [
        tabEventsPlugin(),
        windowEventsPlugin(),
        doNotTrack(),
        AnalyticsMatomo({ tracker })
      ]
    });
    setAnalytics(newAnalytics);
  }, [tracker]);

  const isReady = !!tracker && !!analytics;
  return (
    <AnalyticsContext.Provider value={{ analytics, tracker, setTracker }}>
      {isReady && children}
      <AnalyticsMatomoTracker />
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
