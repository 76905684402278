import { listen } from '@analytics/form-utils';

const AnalyticsForm = () => {
  listen({
    onSubmit: (event, data) => {
      console.log('submit form', event.target);
      console.log('submit data', JSON.stringify(data, null, 2));
    },
    onChange: (event, data) => {
      console.log('change form', event.target);
      console.log('change data', JSON.stringify(data, null, 2));
    }
  });

  return <></>;
};

export default AnalyticsForm;
