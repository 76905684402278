import React, { useContext, useMemo } from 'react';
import { AnalyticsContext } from '@leuven2030/framework/Analytics/AnalyticsProvider';
import { useLocation } from 'react-use';

const AnalyticsLocation = () => {
  const location = useLocation();
  const { analytics } = useContext(AnalyticsContext);

  useMemo(() => {
    const { pathname, search, href } = location;
    setTimeout(() => {
      analytics.page({
        path: pathname,
        search,
        url: href
      });
    }, 50);
  }, [location.pathname]);

  return <></>;
};

export default AnalyticsLocation;
