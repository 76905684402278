import MatomoTracker from '@datapunt/matomo-tracker-js';
import React, { useContext, useEffect } from 'react';
import { AnalyticsContext } from '@leuven2030/framework/Analytics/AnalyticsProvider';

const AnalyticsMatomoTracker = () => {
  const { setTracker, tracker } = useContext(AnalyticsContext);

  useEffect(() => {
    if (tracker) {
      return;
    }
    const newTracker = getTracker();
    setTracker(newTracker);
  }, []);

  function getTracker() {
    const urlBase = process.env.MATOMO_URL;
    const siteId = parseInt(process.env.MATOMO_SITE_ID);
    if (!urlBase || !siteId) {
      return { trackPageView: () => null };
    }
    return new MatomoTracker({
      urlBase: process.env.MATOMO_URL,
      siteId: parseInt(process.env.MATOMO_SITE_ID)
    });
  }
  return <></>;
};

export default AnalyticsMatomoTracker;
