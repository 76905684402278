import React, { createContext, useEffect, useState } from 'react';
import { onScrollChange } from '@analytics/scroll-utils';

export const AnalyticsScrollContext = createContext<{
  trigger?: number;
  direction?: 'down' | 'up';
  scrollMin?: number;
  scrollMax?: number;
  range?: [number, number];
}>({});
const AnalyticsScroll = (props) => {
  const [scrolled, setScrolled] = useState(null);

  function onScrollListener(scrollDepth) {
    setScrolled(scrollDepth);
  }

  useEffect(() => {
    const scrollSteps = [0, 25, 50, 75, 90, 100];
    const scrollStepListeners = {};
    scrollSteps.forEach((scrollStep) => {
      scrollStepListeners[scrollStep] = onScrollListener;
    });
    const detachScrollListener = onScrollChange(scrollStepListeners);

    return () => {
      detachScrollListener();
    };
  });

  return <AnalyticsScrollContext.Provider value={scrolled} {...props} />;
};

export default AnalyticsScroll;
