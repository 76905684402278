import MatomoTracker from '@datapunt/matomo-tracker-js';
export default function AnalyticsMatomo({
  tracker
}: {
  tracker: MatomoTracker;
}) {
  return {
    NAMESPACE: 'matomo',
    initialize: ({ payload }) => {
      //console.log('Load stuff');
    },
    page: async ({
      payload: {
        properties: { url, title }
      }
    }) => {
      //console.log(payload);

      tracker.trackPageView({
        documentTitle: title,
        href: url,
        customDimensions: [] // optional
      });
      //tracker.trackPageView()
    },
    /* Track event */
    track: ({ payload }) => {
      // console.log(
      //   `Example Track > [${payload.event}] [payload: ${JSON.stringify(
      //     payload,
      //     null,
      //     2
      //   )}]`
      // );
    },
    /* Identify user */
    identify: ({ payload }) => {
      // console.log(
      //   `Example identify > [payload: ${JSON.stringify(payload, null, 2)}]`
      // );
    },
    loaded: () => {
      return true;
    },
    ready: () => {
      //console.log('ready: exampleProviderPlugin');
    }
  };
}
